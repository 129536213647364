import { withTranslation, useTranslation } from "react-i18next";
import Navigation from "../../components/navigation/navigation";
import BasicLogo from "../../assets/images/basicLogo.svg";
import WatchidLogo from "../../assets/images/watchidLogo.png";
import YourWatch from "../../assets/icons/your-watch-icon.svg";

import "./maintenance.scss";

const Maintenance = ({ rightToLeft }) => {
  const { t } = useTranslation();
  const maintenanceArr = t("maintenance").split('.');

  return (
    <>
      <Navigation rightToLeft={rightToLeft} />
      <div className="maintenance">
        <div className="imagesWrapper">
          <img className="watchidLogo" alt="" src={WatchidLogo}></img>
          <img className="basicLogo" alt="" src={BasicLogo}></img>
        </div>

        <div className="maintenanceContent">
          <div className="header">{t("header")}</div>
          <div className="subheader">
            {maintenanceArr &&
              maintenanceArr.map((item, index) => (
                <span className="smallTitle" key={index}>
                  {item.length ? item.trim() + "." : ''}
                </span>
              ))
            }
          </div>
          <img className="yourWatch" alt="" src={YourWatch}></img>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(Maintenance);
