export const preferedLanguage = () => {
  // array of suported locales
  const supportedLocales = [
    "en",
    "fr",
    "it",
    "de",
    "es",
    "pt",
    "ja",
    "ko",
    "ru",
    "tr",
    "ar",
    "he",
    "nl",
    "zh_CN",
    "zh_TW",
  ];

  // get selected lang from localStorage
  let selectedLanguage = localStorage.getItem("i18nextLng");

  //reasign the value on change in local storage
  if (selectedLanguage !== localStorage.getItem("i18nextLng")) {
    selectedLanguage = localStorage.getItem("i18nextLng");
  }

  // go through the array and if it is not in there then shorten the selected lang to 2 characters
  // example: if user had set the default language to en-US then the endpoint would return 404
  const shortenedLocale =
    supportedLocales.indexOf(selectedLanguage) >= 0
      ? selectedLanguage
      : selectedLanguage?.substring(0, 2);

  // make en fallback language
  return supportedLocales.indexOf(shortenedLocale) >= 0
    ? shortenedLocale
    : "en";
};
