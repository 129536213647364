import React from "react";
import { withTranslation, useTranslation } from "react-i18next";
import { preferedLanguage } from "./preferedLanguage";
import Close from "../../assets/icons/close.svg";
import WatchidLogo from "../../assets/images/watchidLogo.png";
import BasicLogo from "../../assets/images/basicLogo.svg";
import "./languageSwitcher.scss";

const LanguageSwitcher = ({
  rightToLeft,
  setLanguagesOpen,
  isLanguagesOpen,
}) => {
  const { t, i18n } = useTranslation();

  const languagesArray = [
    { name: "ENGLISH", code: "en" },
    { name: "FRANÇAIS", code: "fr" },
    { name: "ITALIANO", code: "it" },
    { name: "DEUTSCH", code: "de" },
    { name: "ESPAÑOL", code: "es" },
    { name: "PORTUGUÊS", code: "pt" },
    { name: "简体中文", code: "zh_CN" },
    { name: "繁體中文", code: "zh_TW" },
    { name: "日本語", code: "ja" },
    { name: "한국어", code: "ko" },
    { name: "РУССКИЙ", code: "ru" },
    { name: "TÜRKÇE", code: "tr" },
    { name: "العربية", code: "ar" },
    { name: "עברית", code: "he" },
    { name: "NEDERLANDS", code: "nl" },
  ];
  return (
    <>
      <div className={`dropdownWrapper ${!isLanguagesOpen ? "hidden" : ""} `}>
        <div
          className={`closeButton ${rightToLeft ? "languageSwitcherRTL" : ""}`}
          onClick={() => setLanguagesOpen(!isLanguagesOpen)}
        >
          <img alt="" src={Close}></img>
        </div>
        <div className="imagesWrapper">
          <img
            className={`watchidLogo ${rightToLeft ? "watchidLogoRTL" : ""}`}
            alt=""
            src={WatchidLogo}
          ></img>
          <img className="basicLogo" alt="" src={BasicLogo}></img>
        </div>

        <div className="textWrapper">
          <div className="heading">{t("selectLanguage")}</div>

          {languagesArray.map((language, index) => (
            <div
              className={
                preferedLanguage() === language.code ? "selectedLanguage" : null
              }
              onClick={() =>
                i18n.changeLanguage(language.code) & window.location.reload()
              }
              key={index}
            >
              {language.name}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default withTranslation()(LanguageSwitcher);
