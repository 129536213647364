import React, { useState } from "react";
import { withTranslation, useTranslation } from "react-i18next";
import { preferedLanguage } from "../languageSwitcher/preferedLanguage";
import LanguageSwitcher from "../../components/languageSwitcher/languageSwitcher";
import ServiceCenter from "../../assets/icons/service-center.svg";
import TudorWatch from "../../assets/icons/tudor-watch.svg";
import Globe from "../../assets/icons/globe.svg";

import "./navigation.scss";

const Navigation = ({ rightToLeft }) => {
  const { t } = useTranslation();

  const [navOpen, setNavOpen] = useState(false);
  const [isLanguagesOpen, setLanguagesOpen] = useState(false);
  const selectedLanguage =
    preferedLanguage() === "zh_CN"
      ? "zh-hant"
      : preferedLanguage() === "zh_TW"
      ? "zh-hans"
      : preferedLanguage();
  return (
    <>
      <div
        className={rightToLeft ? "navIconRTL" : "navIcon"}
        onClick={() => setNavOpen(!navOpen)}
      >
        <div className={navOpen ? "cross" : "hamburger"}></div>
        <div className={navOpen ? "cross" : "hamburger"}></div>
        <div className={navOpen ? "cross" : "hamburger"}></div>
      </div>

      <div className={!navOpen ? "overlay" : "overlayShown"}></div>

      <div
        id={rightToLeft ? "sliderRTL" : "slider"}
        className={navOpen ? "slide-in" : ""}
      >
        {!rightToLeft && <ul className="navigation2"></ul>}
        <ul className="navigation">
          <li>
            <a
              href={`https://www.tudorwatch.com/${selectedLanguage}/retailers?cmpid=tudorwatchid_tudorwatch.com`}
              rel="noreferrer"
              target="_blank"
            >
              <img alt="" src={ServiceCenter}></img> {t("serviceCenter")}
            </a>
          </li>
          <li>
            <a
              href={`https://www.tudorwatch.com/${selectedLanguage}?cmpid=tudorwatchid_tudorwatch.com`}
              rel="noreferrer"
              target="_blank"
            >
              <img alt="" src={TudorWatch}></img> Tudorwatch
            </a>
          </li>
          <li onClick={() => setLanguagesOpen(!isLanguagesOpen)}>
            <img className="languages" alt="" src={Globe}></img> Languages
          </li>
        </ul>
      </div>
      <LanguageSwitcher
        rightToLeft={rightToLeft}
        setLanguagesOpen={setLanguagesOpen}
        isLanguagesOpen={isLanguagesOpen}
      />
    </>
  );
};

export default withTranslation()(Navigation);
